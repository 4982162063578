.form-field {
    flex: 1;
}

.form-field input,
.form-field select,
.form-field textarea {
    display: block;
    border: 1px solid #f1f1f1;
    padding: 12.5px;
    border-radius: 2px;
    width: 100%;
    margin: 10px 0px;
    background-color: #f4f4f4;
}

.form-field .error-field {
    font-size: 0.9em;
    color: red;
    margin: 10px auto;
    /* padding: 10px; */
}
.form-field span {
    margin: 10px 0px;
    display: block;
}
.form-field textarea {
    height: 150px;
    resize: vertical;
    resize: none;
}
