@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&family=Space+Grotesk:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Zen+Kaku+Gothic+New:wght@400;700;900&display=swap");
/* font-family: 'Zen Kaku Gothic New', sans-serif; */
/* font-family: 'Bangers', cursive; */
@font-face {
    src: url(./assets/fonts/proxima.ttf);
    font-family: Proxima;
}

* {
    margin: 0;
    padding: 0;
    font-family: Proxima;
    box-sizing: border-box;
    /* font-family: "Inter", sans-serif; */
    font-family: "Zen Kaku Gothic New", sans-serif;
}
:root {
    --primary-color: #117ab9;
}
a {
    text-decoration: none;
    color: #000;
}

input,
textarea {
    outline: none;
}

button {
    height: auto;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bolder;
}

p {
    margin-bottom: 0em;
}
